<template>
  <div class="order-list">
    <table class="order-table">
      <thead>
        <tr>
          <th>回收信息</th>
          <th>用户单价/回收站单价/数量</th>
          <th>用户订单金额</th>
          <th>回收站支付金额</th>
          <th>预约人</th>
          <th>预约地址</th>
          <th>接单人</th>
          <th>下单时间</th>
          <th>预约时间</th>
          <th>订单状态</th>
          <th>操作</th>
        </tr>
        <tr style="height: 10px"></tr>
      </thead>
      <tbody v-if="!dataList.length">
        <tr style="background: #fff">
          <td colspan="11">
            <No />
          </td>
        </tr>
      </tbody>
      <tbody v-for="(item, index) in dataList" :key="index">
        <tr>
          <td colspan="11">
            <div class="allCol">
              <div>订单编号:{{ item.ordersn }}</div>
              <el-button size="small" type="text" @click="$router.push('/order/orderDetail?id=' + item.id)">订单详情</el-button>
            </div>
          </td>
        </tr>
        <tr class="showInfoTr">
          <td colspan="2">
            <!-- 展示货物 -->
            <div class="goodsInfo">
              <div :class="['showGoods', item.ordergoods.length > 1 && index1 !== item.ordergoods.length - 1 ? 'isNeedBot' : '']" v-for="(item1, index1) in item.ordergoods" :key="index1">
                <div class="left">
                  <img :src="item1.images" alt="" />
                  <div class="goodsInfo">
                    <div>{{ item1.goods_name }}</div>
                    <div>{{ item1.name }}</div>
                  </div>
                </div>
                <div class="right">
                  <div>￥{{ item1.cost_price + ' /' }}</div>
                  <div>￥{{ item1.recycle_price }}</div>
                  <div>x{{ item1.num + item1.unit }}</div>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div>
              <div>￥{{ item.price }}</div>
              <div>公益值：{{ item.energy }}</div>
            </div>
          </td>
          <td>￥{{ item.recycle_bin_price || item.recycle_price }}</td>
          <td>
            <div>
              <div>{{ item.nick_name }}</div>
              <div>{{ item.name }}</div>
              <div>{{ item.tel }}</div>
            </div>
          </td>
          <td>
            {{ item.address || '--' }}
          </td>
          <td>
            <div v-if="item.order_name && item.order_tel">
              <div>{{ item.order_name }}</div>
              <div>{{ item.order_tel }}</div>
            </div>
            <div v-else>--</div>
          </td>
          <td>
            {{ item.create_time ? getDateformat(item.create_time) : '--' }}
          </td>
          <td>
            <div v-if="item.order_door_time">
              <div>{{ item.order_door_time.split(',')[0] }}</div>
              <div>{{ item.order_door_time.split(',')[1] }}</div>
            </div>
            <div v-else>--</div>
          </td>
          <td>
            <div :class="[item.status === -20 || item.status === -10 || item.status === 0 ? 'error' : item.status === 30 ? 'warning' : 'success']">
              {{
                item.status === -20
                  ? '后台取消'
                  : item.status === -10
                  ? '回收员取消'
                  : item.status === 20
                  ? '已接单'
                  : item.status === 30
                  ? '待上门'
                  : item.status === 40
                  ? '待入账'
                  : item.status === 50
                  ? '已完成'
                  : item.status === 0
                  ? '已取消'
                  : '待接单'
              }}
            </div>
          </td>
          <td class="operation">
            <div class="success" v-if="item.status === 10" @click="cancleOrder(item)">取消订单</div>
            <div class="priBtn" v-if="item.status === 10 && item.need_pai" @click="distributeFn(item)">派单</div>
          </td>
        </tr>
      </tbody>
    </table>
    <el-dialog title="派单" :visible.sync="distributeFlag" width="800px" :before-close="handleClose">
      <el-form :model="chooseThat" status-icon :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="预约人：">{{ chooseThat.name + '/' + chooseThat.tel }}</el-form-item>
        <el-form-item label="预约时间：">{{ chooseThat.order_door_time }}</el-form-item>
        <el-form-item label="预约服务地址：">{{ chooseThat.address }}</el-form-item>
        <el-form-item label="回收员区域：" prop="areaId">
          <el-cascader
            v-model="chooseThat.areaId"
            :props="{ value: 'id', label: 'name', children: '_child' }"
            placeholder="请选择或搜索"
            filterable
            :options="areaList"
            @change="changeArea"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="选择回收员：" prop="chooseRegionalID">
          <el-select v-model="chooseThat.chooseRegionalID" placeholder="请选择">
            <el-option v-for="item in regionalList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="派单备注：" prop="remark">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" placeholder="请输入内容" v-model="chooseThat.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="distributeFlag = false">取 消</el-button>
        <el-button type="primary" @click="sureDistribute">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getDateformat } from '@/util/getDate.js';
export default {
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    function checkAreaId(rule, value, callback) {
      if (!value || value.length !== 3) {
        callback(new Error('请选择回收员区域'));
      } else {
        callback();
      }
    }
    return {
      getDateformat,
      distributeFlag: false,
      chooseThat: {},
      ruleForm: {},
      rules: {
        areaId: [{ validator: checkAreaId, trigger: 'change' }],
        chooseRegionalID: [{ required: true, message: '请选择回收员', trigger: 'change' }],
        remark: [{ required: true, message: '请填写备注', trigger: 'change' }],
      },
      areaList: [],
      regionalList: [],
    };
  },
  created() {
    this.getArea();
  },
  methods: {
    getArea() {
      this.$axios.get(this.$api.set.area, { tree: 1, level: 2 }).then(res => {
        if (res.code == 0) {
          this.areaList = res.result.list;
        }
      });
    },
    // 取消订单
    cancleOrder(data) {
      this.$confirm('此操作将取消该订单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.O2O.order.cancle, { id: data.id });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('已成功取消订单');
            this.$emit('updateList');
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    // 开启派单弹框
    distributeFn(data) {
      this.chooseThat = data;
      this.$set(this.chooseThat, 'areaId', '');
      this.$set(this.chooseThat, 'chooseRegionalID', '');
      this.$set(this.chooseThat, 'remark', '');
      this.distributeFlag = true;
    },
    changeArea() {
      if (!this.chooseThat.areaId) return;
      this.chooseThat.chooseRegionalID = '';
      let obj = {
        page: 1,
        rows: 10000,
        area_id: this.chooseThat.areaId[2],
      };
      this.$axios.post(this.$api.O2O.recycler.recylerList, obj).then(res => {
        if (res.code === 0) {
          this.regionalList = res.result.list;
        }
      });
    },
    // 点击确认派单按钮
    sureDistribute() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.O2O.order.paiOrder, {
              id: this.chooseThat.id,
              recyler_id: this.chooseThat.chooseRegionalID,
              remark: this.chooseThat.remark,
            })
            .then(res => {
              if (res.code === 0) {
                this.$message.success('派单成功');

                this.$emit('updateList');
                this.distributeFlag = false;
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-table {
  width: 100%;
  font-size: 14px;
  tbody {
    border: 1px solid #ebeef5;
  }
  thead tr th {
    user-select: none;
    padding: 15px;
    text-align: center;
    color: #909399;
  }
  thead tr {
    background: #f8f9fa;
    white-space: nowrap;
    th:first-child {
      width: 140px;
    }
    th:last-child {
      width: 100px;
    }
  }
  tbody tr:first-child {
    border-bottom: 1px solid #ebeef5;

    background: #f8f9fa;
    td {
      padding: 5px 20px;
    }
  }
  tbody tr:last-child {
    td {
      text-align: center;
    }
    td:last-child {
      width: 100px;
    }
  }
}
.allCol {
  width: 100%;
  background-color: #f9f9f9;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goodsInfo {
  .isNeedBot {
    border-bottom: 1px solid #ebeef5;
  }
  .showGoods {
    display: flex;
    padding: 10px 30px;

    .left {
      display: flex;
    }
    .right {
      font-size: 14px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      & > div {
        white-space: nowrap;
      }
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 5px;
      margin-right: 10px;
    }
    .goodsInfo {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.operation {
  & > div {
    width: 80px;
    border-radius: 3px;
    cursor: pointer;
    margin: 0 auto;
  }

  .priBtn {
    padding: 5px;
    color: #fff;
    background-color: #409eff;
    margin-top: 10px;
  }
}
.showInfoTr {
  td {
    padding: 5px;
  }
}
.error {
  color: #ff7978;
}
.warning {
  color: #fdc074;
}
.success {
  color: #409eff;
}
</style>
